<template>
  <div class="content">
    <h1 class="text">This is an home page</h1>
    <div class="button" @click="handle" v-if="show">
        业务接口
    </div>
    {{author}}<br/>
    {{userInfo}}
  </div>
</template>

<script>
// import {macApi} from './fetch'
export default {
  name:'',
  components:{
  },
  data() {
    return {
      code: this.$route.query.code,
      show: true,
      author: '未授权',
      userInfo: {

      }
    }
  },
  computed: {
},
async created () {
   
    
  },
 async mounted(){
    if(this.code){
      this.show = false
      this.getAuthTokenByCode(this.code)
     
    }
  },  
  methods: {
    // 调用逻辑接口
    async handle() {
      
      const res = await fetch('/macos/mac/',{
         method: 'get',
         
      })
    
      console.log(res);

      if(res.status === 401){
        // 去授权页面
        this.$router.push({path:'/outServe/auth'})
      } else {
        const {data} = await res.json()
        this.userInfo = data
      }
      
    },

 
    
  

    async  getAuthTokenByCode () {
      const res = await fetch(`/macos/mac/token?code=${this.code}`)
      const {status,data} = await res.json()
      this.show = this.code && status ==='00000'
      this.author = data
      // console.log(resData)
    }


  },
}
</script>

<style lang='scss' scoped>
.content {
  margin: 10px auto;
}
.button {
  width: 100px;
  height: 40px;
  background-color: #258aff;
  line-height: 40px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}
</style>
